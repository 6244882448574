import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { DomainCatalogThumbnailService } from './thumbnail.service';

@Pipe({
  name: 'thumbnailUrl',
  pure: false,
})
export class ThumbnailUrlPipe implements PipeTransform, OnDestroy {
  private input$ = new BehaviorSubject<{ width?: number; height?: number; ean?: string }>(undefined);
  private result: string;

  private onDestroy$ = new Subject();

  constructor(private domainCatalogThumbnailService: DomainCatalogThumbnailService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.input$.complete();
  }

  transform(ean: string, width?: number, height?: number): any {
    this.input$.next({ ean, width, height });

    this.input$
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((input) => this.domainCatalogThumbnailService.getThumnaulUrl(input))
      )
      .subscribe((result) => {
        this.result = result;
        this.cdr.markForCheck();
      });

    return this.result;
  }
}
