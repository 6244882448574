import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AuthService } from '@core/auth';
import { DialogModel, UiDialogModalComponent, UiErrorModalComponent, UiModalService } from '@ui/modal';
import { IsaLogProvider } from './isa.log-provider';
import { LogLevel } from '@core/logger';

@Injectable({ providedIn: 'root' })
export class IsaErrorHandler implements ErrorHandler {
  constructor(private _modal: UiModalService, private _authService: AuthService, private _isaLogProvider: IsaLogProvider) {}

  async handleError(error: any): Promise<void> {
    console.error(error);

    // Bei Klick auf Abbrechen auf der Login Seite erneut zur Login Seite weiterleiten
    if (error?.type === 'token_error') {
      this._authService.login();
      return;
    }

    if (error instanceof HttpErrorResponse && error?.status === 401) {
      await this._modal
        .open({
          content: UiDialogModalComponent,
          title: 'Sitzung abgelaufen',
          data: {
            handleCommand: false,
            content: 'Sie waren zu lange nicht in der ISA aktiv. Bitte melden Sie sich erneut an',
            actions: [{ command: 'CLOSE', selected: true, label: 'Erneut anmelden' }],
          } as DialogModel,
        })
        .afterClosed$.toPromise();

      this._authService.logout();
      return;
    }

    this._isaLogProvider.log(LogLevel.ERROR, 'Client Error', error);

    this._modal.open({
      content: UiErrorModalComponent,
      title:
        !navigator.onLine || (error instanceof HttpErrorResponse && error?.status === 0)
          ? 'Sie sind offline, keine Verbindung zum Netzwerk'
          : 'Unbekannter Fehler',
      data: error,
    });
  }
}
