import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { ApplicationService } from '@core/application';
import { Config } from '@core/config';
import { NotificationsHub } from '@hub/notifications';
import packageInfo from 'package';
import { asapScheduler, interval, Observable, Subscription } from 'rxjs';
import { UserStateService } from '@swagger/isa';
import { IsaLogProvider } from './providers';
import { EnvironmentService } from '@core/environment';
import { AuthService } from '@core/auth';
import { UiMessageModalComponent, UiModalService } from '@ui/modal';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private _checkForUpdates: number = this._config.get('checkForUpdates');
  updateAvailableObs: Observable<UpdateAvailableEvent>;

  get checkForUpdates(): number {
    return this._checkForUpdates;
  }

  // For Unit Testing
  set checkForUpdates(time: number) {
    this._checkForUpdates = time;
  }

  subscriptions = new Subscription();

  constructor(
    private readonly _config: Config,
    private readonly _title: Title,
    private readonly _appService: ApplicationService,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _renderer: Renderer2,
    private readonly _swUpdate: SwUpdate,
    private readonly _notifications: NotificationsHub,
    private infoService: UserStateService,
    private readonly _environment: EnvironmentService,
    private readonly _authService: AuthService,
    private readonly _modal: UiModalService
  ) {
    this.updateClient();
    IsaLogProvider.InfoService = this.infoService;
  }

  ngOnInit() {
    this.setTitle();
    this.logVersion();
    asapScheduler.schedule(() => this.determinePlatform(), 250);
    this._appService.getSection$().subscribe(this.sectionChangeHandler.bind(this));

    this.setupSilentRefresh();
  }

  // Setup interval for silent refresh
  setupSilentRefresh() {
    const silentRefreshInterval = this._config.get('silentRefresh.interval');
    if (silentRefreshInterval > 0) {
      interval(silentRefreshInterval).subscribe(() => {
        if (this._authService.isAuthenticated()) {
          this._authService.refresh();
        }
      });
    }
  }

  setTitle() {
    this._title.setTitle(this._config.get('title'));
  }

  logVersion() {
    console.log(`%c${this._config.get('title')}\r\nVersion: ${packageInfo.version}`, 'font-weight: bold; font-size: 20px;');
  }

  determinePlatform() {
    if (this._environment.isNative()) {
      this._renderer.addClass(this._document.body, 'tablet-native');
    } else if (this._environment.isTablet()) {
      this._renderer.addClass(this._document.body, 'tablet-browser');
    }
    if (this._environment.isTablet()) {
      this._renderer.addClass(this._document.body, 'tablet');
    }
    if (this._environment.isDesktop()) {
      this._renderer.addClass(this._document.body, 'desktop');
    }
  }

  sectionChangeHandler(section: string) {
    if (section === 'customer') {
      this._renderer.removeClass(this._document.body, 'branch');
      this._renderer.addClass(this._document.body, 'customer');
    } else if (section === 'branch') {
      this._renderer.removeClass(this._document.body, 'customer');
      this._renderer.addClass(this._document.body, 'branch');
    }
  }

  updateClient() {
    if (!this._swUpdate.isEnabled) {
      return;
    }

    this.initialCheckForUpdate();
    this.checkForUpdate();
  }

  checkForUpdate() {
    interval(this._checkForUpdates).subscribe(() => {
      this._swUpdate.checkForUpdate().then((value) => {
        console.log('check for update', value);
        if (value) {
          this._notifications.updateNotification();
        }
      });
    });
  }

  initialCheckForUpdate() {
    this._swUpdate.checkForUpdate().then((value) => {
      console.log('initial check for update', value);
      if (value) {
        location.reload();
      }
    });
  }

  @HostListener('window:visibilitychange', ['$event'])
  onVisibilityChange(event: Event) {
    // refresh token when app is in background
    if (this._document.hidden && this._authService.isAuthenticated()) {
      this._authService.refresh();
    } else if (!this._authService.isAuthenticated()) {
      return this._modal
        .open({
          content: UiMessageModalComponent,
          title: 'Sie sind nicht mehr angemeldet',
          data: { message: 'Sie werden neu angemeldet' },
        })
        .afterClosed$.subscribe(() => {
          this._authService.login();
        });
    }
  }
}
