import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NEVER, Observable, throwError } from 'rxjs';
import { UiMessageModalComponent, UiModalService } from '@ui/modal';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '@core/auth';
import { IsaLogProvider } from '../providers';
import { LogLevel } from '@core/logger';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _modal: UiModalService, private _auth: AuthService, private _isaLogProvider: IsaLogProvider) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error: HttpErrorResponse, caught: any) => this.handleError(error)));
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 0) {
      return this._modal
        .open({
          content: UiMessageModalComponent,
          title: 'Sie sind offline, keine Verbindung zum Netzwerk',
          data: { message: 'Bereits geladene Inhalte werden angezeigt. Interaktionen sind aktuell nicht möglich.' },
        })
        .afterClosed$.pipe(mergeMap(() => throwError(error)));
    } else if (error.status === 401) {
      console.log('401', error);
      return this._modal
        .open({
          content: UiMessageModalComponent,
          title: 'Sie sind nicht mehr angemeldet',
          data: { message: 'Sie werden neu angemeldet' },
        })
        .afterClosed$.pipe(
          tap(() => {
            this._auth.login();
          }),
          mergeMap(() => NEVER)
        );
    }

    this._isaLogProvider.log(LogLevel.ERROR, 'Http Error', error);

    return throwError(error);
  }
}
